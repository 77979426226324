import { useCurrentPage } from "./contexts/currentPage";
import { shortcuts } from "./constants";
import ReturnPageButton from "./components/ReturnPageButton";
import Shortcuts from "./pages/Shortcuts";
import { pages } from "./links";

function App() {
  const { currentPage } = useCurrentPage();

  return (
    <>
      {currentPage !== pages.MAIN && <ReturnPageButton />}
      <Shortcuts shortcut={shortcuts[currentPage]} />
    </>
  );
}

export default App;
