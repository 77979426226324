import { Container, Title } from "../styles/Home";
import LinksList from "../components/LinksList";

export default function Shortcuts({ shortcut }) {
  return (
    <Container>
      <Title>{shortcut.title}</Title>
      <LinksList linksList={shortcut.links} />
    </Container>
  );
}
