import styled from "styled-components";

export const Button = styled.button`
  position: absolute;
  top: 5%;
  left: 15%;
  display: flex;
  gap: 15px;
  padding: 10px 18px;
  background-color: #f5f7f8;
  color: #11111;
  border: 0;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
`;