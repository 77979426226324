export const pages = {
  MAIN: 0,
  TECH: 1,
  TAXES: 2,
};

const folderImage =
  "https://png.pngtree.com/png-vector/20220811/ourmid/pngtree-flat-design-blue-folder-icon-vector-vector-illustration-design-vector-png-image_48047698.jpg";

export const GENERAL_LINKS = [
  {
    title: "Tech",
    image: folderImage,
    destination: pages.TECH,
    isFolder: true,
  },
  {
    title: "Haciendas",
    image: folderImage,
    destination: pages.TAXES,
    isFolder: true,
  },
  {
    title: "A9 Tráfico",
    image: "https://gaa9.com/assets/img/a9trafico-min.png",
    destination: "https://beta.a9trafico.com/",
  },
  {
    title: "A9 Firma",
    image: "https://gaa9.com/assets/img/a9firma.png",
    destination: "https://a9firma.gaa9.com/",
  },
  {
    title: "Pago tasas",
    image: "https://shepron.es/wp-content/uploads/dgt-shepron.jpg",
    destination: "https://sedeclave.dgt.gob.es/WEB_Tasas7/",
  },
  {
    title: "Gmail",
    image:
      "https://cdn.vox-cdn.com/thumbor/x1-9UwiB_BeLufaPfDTRaLrACBo=/0x0:1320x880/1200x628/filters:focal(660x440:661x441)/cdn.vox-cdn.com/uploads/chorus_asset/file/21939811/newgmaillogo.jpg",
    destination: "https://mail.google.com/mail/",
  },
  {
    title: "Drive",
    image:
      "https://d3t4nwcgmfrp9x.cloudfront.net/upload/consejos-google-drive.jpg",
    destination: "https://drive.google.com/drive/my-drive",
  },
  {
    title: "Ads",
    image:
      "https://i.pinimg.com/736x/bb/82/91/bb82916001764317cb68ecb8d221f100.jpg",
    destination:
      "https://ads.google.com/aw/overview?ocid=6511825843&euid=1132567150&__u=9830260350&uscid=6511825843&__c=8188255707&authuser=2&workspaceId=0&subid=es-es-awhp-g-aw-c-home-signin!o2-adshp-hv-q4-22",
  },
  {
    title: "Totalum",
    image:
      "https://www.totalum.app/totalum-web-v2/img/totalum/totalum-logo-recortado.png",
    destination: "https://web.totalum.app/table/pedidos",
  },
  {
    title: "Herramientas PDF",
    image:
      "https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/31/53/c0/3153c067-8211-0347-f47e-7b512cf9df01/ReleaseAppIcon-0-0-1x_U007emarketing-0-7-0-85-220.png/1200x630wa.png",
    destination: "https://www.ilovepdf.com/es",
  },
  {
    title: "Tickets A9 (Soporte)",
    image:
      "https://media.istockphoto.com/id/1412305573/es/vector/icono-de-soporte-aislado-sobre-fondo-blanco.jpg?s=612x612&w=0&k=20&c=7wGp6kkhDffvxmWCPjjUUUsS55YOuCR_KMMrAMZbLkw=",
    destination: "https://sd.gaa9.com",
  },
];

export const ITP_LINKS = [
  {
    title: "Catalunya",
    image:
      "https://atc.gencat.cat/web/resources/fwkResponsive/fpca_capcalera_seuNou/img/atc-color.svg",
    destination:
      "https://atc.gencat.cat/es/tributs/itpajd/tpo/tramit-compravenda-vehicle/index.html?moda=1&detallId=26b10607-b79e-11e6-ab26-000c29052e2c",
  },
  {
    title: "Madrid",
    image:
      "https://gestiona.comunidad.madrid/opti_webapp_contrib/assets/logo-app.png",
    destination:
      "https://gestiona.comunidad.madrid/opti_webapp_contrib/#/landing-view",
  },
  {
    title: "Castilla y la Mancha",
    image:
      "https://portaltributario.jccm.es/profiles/jccm/themes/jccm_2016/images/logo-jccm-cabecera.png",
    destination:
      "https://portaltributario.jccm.es/oficina-electronica/modelos/620-transmision-de-determinados-medios-de-trasporte-usados-vehiculos-4",
  },

  {
    title: "Valencia",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Imagotip_de_la_Generalitat_Valenciana.svg/300px-Imagotip_de_la_Generalitat_Valenciana.svg.png",
    destination:
      "https://atv.gva.es/es/tributos-impuestos-declaraciones-tp-620-sara5",
  },
  {
    title: "Andalucía",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Logotipo_de_la_Junta_de_Andaluc%C3%ADa_2020.svg/200px-Logotipo_de_la_Junta_de_Andaluc%C3%ADa_2020.svg.png",
    destination:
      "https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://www.juntadeandalucia.es/economiayhacienda/apl/surweb/modelos/modelo621/621.jsp&ved=2ahUKEwiBjP7JwvaGAxXP_7sIHQrMBdgQFnoECBgQAQ&usg=AOvVaw0_z1gdHKKPii0ZkXh6pwM8",
  },
  {
    title: "Murcia",
    image:
      "https://cdn.domestika.org/c_fit,dpr_2.0,f_auto,q_80,t_base_params,w_820/v1620632606/content-items/007/886/022/ATRM%2520thumbnail%2520hover-original.png?1620632606",
    destination:
      "https://pase.carm.es/pase/login?service=https%3A%2F%2Fetributos.carm.es%2Fetributos%2FindexPortal.html",
  },
  {
    title: "Aragón",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/Logotipo_del_Gobierno_de_Aragón.svg/360px-Logotipo_del_Gobierno_de_Aragón.svg.png",
    destination: "https://aplicaciones.aragon.es/yafarcentral/",
  },
  {
    title: "Castilla y León",
    image:
      "https://www.tramitacastillayleon.jcyl.es/futuretense_cs/JCYL_17/img/jcyl.jpg",
    destination:
      "https://servicios4.jcyl.es/access-control/ovia2-Usuario-presentation/FormWeb620/?ACCESO=ACCESO_CON_CERTIFICADO&PERFIL=GENERAL&FUNCIONALIDAD=FORMWEB620",
  },
  {
    title: "Galicia",
    image:
      "https://www.atriga.gal/portada-atriga-theme/images/portal/logoAtriga.png",
    destination: "https://ovt.atriga.gal",
  },
  {
    title: "Islas Baleares",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/Escudo_de_las_Islas_Baleares.svg/159px-Escudo_de_las_Islas_Baleares.svg.png",
    destination:
      "https://sede.atib.es/cva/acceso/clave?returnUrl=%2Fcva%2Ftributos%2Ftributos-autonomicos%2F621%2Fnuevo",
  },
  {
    title: "Canarias",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Escudo_de_Canarias.svg/176px-Escudo_de_Canarias.svg.png",
    destination:
      "https://www3.gobiernodecanarias.org/tributos/atc/jsf/publico/asistenciaContribuyente/modelos/modelos.jsp?codmodelo=420",
  },
  {
    title: "Asturias",
    image:
      "https://sede.tributasenasturias.es/recursosWeb/sede/images/portal-stpa.gif",
    destination:
      "https://sede.tributasenasturias.es/sites/sede/default/es_ES/Modelos-tributarios-especiales?cc1_modelo=620&pt_modelo=620&pt_nuevaCarga=s&esSiga=N&cc1_accion=C&pt_accion=C",
  },
  {
    title: "Extremadura",
    image:
      "https://yt3.googleusercontent.com/Jkuqt0G7yyKGKhseJ9MzhuwPCHJVUFSCDaD6carGRhzSc04Cud3npng_DcyqY2diXNy6MdkfNA=s900-c-k-c0x00ffffff-no-rj",
    destination:
      "https://sede.gobex.es/SEDE/estructura/accesoClave.jsf?opcion=32",
  },
  {
    title: "Navarra",
    image: "https://finnova.eu/wp-content/uploads/2020/09/navarra-gobierno.png",
    destination:
      "https://hacienda.navarra.es/distribuidor/frmDT.aspx?familia=ITPAJ",
  },
];

export const TECH_LINKS = [
  {
    title: "Desarrollo página Wordpress",
    image: "https://pngimg.com/d/wordpress_PNG31.png",
    destination: "https://autotrafic.es/wp-admin",
  },
  {
    title: "Webmail",
    image:
      "https://cdn.statically.io/img/softmanagement.com.co/wp-content/uploads/2022/11/webmail-2.png?quality=100&f=auto",
    destination: "https://webmail.gestiondecorreo.com/",
  },
  {
    title: "Panel del servidor inicial",
    image:
      "https://thumbs.dreamstime.com/b/icono-logo-design-element-del-servidor-de-la-nube-95429269.jpg",
    destination: "https://paneles.gestiondecuenta.com/",
  },
  {
    title: "Accounts",
    image:
      "https://as1.ftcdn.net/v2/jpg/03/60/51/38/1000_F_360513894_zB0qlGCDMw3RV6RvHGyqUX9Vlo4rPOnE.jpg",
    destination:
      "https://drive.google.com/file/d/1kE1BSoIHG8am4G28-Uc2Z-nNbUDcuHX-/view?usp=drive_link",
  },
];
