import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 550px;
  height: 40px;
  background-color: #fff;
  border-radius: 50px;
  padding: 5px 10px;
  gap: 20px;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  border: 0;
  outline: none;
  background-color: transparent;
  margin: 0;
  flex: 1;
`;