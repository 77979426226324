import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useCurrentPage } from "../contexts/currentPage";
import { Button } from "../styles/ReturnPageButton/ReturnPageButton";

export default function ReturnPageButton() {
  const { goBack } = useCurrentPage();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Backspace") {
        goBack();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [goBack]);

  return (
    <Button onClick={goBack}>
      <FontAwesomeIcon icon={faArrowLeft} />
      Atrás
    </Button>
  );
}
