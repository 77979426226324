import { useCurrentPage } from "../contexts/currentPage";
import { NavigationLink, Container, Image, Title } from "../styles/Link";

export default function Link({ title, image, destination, isFolder }) {
  const { updateCurrentPage } = useCurrentPage();

  const openFolder = (e) => {
    e.preventDefault();
    updateCurrentPage(destination);
  };

  return (
    <NavigationLink
      href={isFolder ? "#" : destination}
      target="_blank"
      onClick={isFolder && openFolder}
    >
      <Container>
        <Image src={image} width={250} height={150} />

        <Title>{title}</Title>
      </Container>
    </NavigationLink>
  );
}
