import styled from 'styled-components';

export const NavigationLink = styled.a`
  text-decoration: none;
  max-width: 250px;
  margin-bottom: 3em;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1em;
  flex-direction: column;
  cursor: pointer;
`;

export const Image = styled.img`
  object-fit: cover;
  border-radius: 15px;
  border: 0;
  background-color: white;
  padding: 0.5em;
`;

export const Title = styled.h3`
  color: white;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
  margin: 0.6em 0;
`;
