import { useState } from "react";
import { Container, Input } from "../styles/GoogleInput";
import { GoogleLogo } from "../assets/svgs";

const googleImg = "/google-logo.svg";

export default function GoogleInput() {
  const [query, setQuery] = useState("");

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(query)}`;
      window.open(searchUrl, "_blank");
    }
  };

  return (
    <Container>
      <GoogleLogo />
      <Input
        autoFocus
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        placeholder="Buscar en Google o escribir una URL"
      />
    </Container>
  );
}
