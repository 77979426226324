import { List } from "../styles/LinksList";
import GoogleInput from "./GoogleInput";
import Link from "./Link";

export default function LinksList({ linksList }) {
  if (!linksList) return;

  return (
    <>
      <GoogleInput />
      <List>
        {linksList.map((link) => (
          <Link
            key={link.title}
            title={link.title}
            image={link.image}
            destination={link.destination}
            isFolder={link.isFolder}
          />
        ))}
      </List>
    </>
  );
}
